<template>
    <transition name="player-animation">
        <div
            v-show="showPlayer"
            id="mkAudioPlayer"
            :aria-hidden="!showPlayer"
            class="mkAudioPlayerBox @px-5 tablet:@pl-8 @pt-6 @pb-8 tablet:@py-6"
            @click="$sfPlayer.removeMinify()"
        >
            <div class="@container @px-0">
                <div class="@grid @grid-cols-12 @gap-x-0">
                    <div class="@col-span-12 tablet:@col-span-6 tablet-lg:@col-span-5">
                        <div class="songDetails">
                            <slot :audio="currentlyPlayingAudio" name="songDetails"/>
                            <template v-if="!$slots.songDetails">
                                <div class="frameBox @mr-5">
                                    <a v-if="currentlyPlayingAudio.link" :href="currentlyPlayingAudio.link">
                                        <RoundImageWithDefault
                                            :image="currentlyPlayingAudio.image"
                                            :alt="currentlyPlayingAudio.name"
                                        />
                                    </a>
                                </div>
                                <div>
                                    <div v-if="currentlyPlayingAudio.link" class="h5 @mb-[3px] @underline @underline-offset-2 playerMinified">
                                        <a v-if="currentlyPlayingAudio.link" :href="currentlyPlayingAudio.link" v-text="currentlyPlayingAudio.name"/>
                                    </div>
                                    <div v-if="currentlyPlayingAudio.song_title" class="p3 @mb-[3px] tablet:@mt-0" v-text="currentlyPlayingAudio.song_title"/>
                                    <div v-if="currentlyPlayingAudio.song_subtitle" class="p4" v-text="currentlyPlayingAudio.song_subtitle"/>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="@col-span-12 tablet:@col-span-6 tablet-lg:@col-span-7 @flex tablet:@justify-end @items-center @justify-center">
                        <div class="mkAudioPlayer">
                            <div class="controlPanel @mb-0">
                                <button v-if="showNavigation" class="prev" aria-label="previous song" @click.stop="$sfPlayer.playNext(-1)">
                                    <img :src="$sfPlayer.icons.nextSong" alt="previous song icon">
                                </button>

                                <DButton
                                    ref="playButton"
                                    dataTestId="play-audio-button"
                                    theme="white"
                                    variant="circle"
                                    :icon="isPlaying ? 'pause' : 'play'"
                                    aria-label="Play/Pause"
                                    @click.stop="$sfPlayer.toggle()"
                                />

                                <button v-if="showNavigation" aria-label="next song" @click.stop="$sfPlayer.playNext(1)">
                                    <img :src="$sfPlayer.icons.nextSong" alt="next song icon">
                                </button>
                            </div>

                            <div class="mkAudioProgressBar @flex @items-center">
                                <div class="p4">
                                    {{currentTime}}
                                </div>
                                <div class="@relative @mx-0 tablet:@mx-4 @w-full">
                                    <div class="range @flex">
                                        <input
                                            ref="input"
                                            v-model="$sfPlayer.currentRange"
                                            :max="maxRange"
                                            type="range"
                                            aria-label="seek bar"
                                            tabindex="-1"
                                        >
                                    </div>
                                    <div class="helper" :class="{show: playerClass}">
                                        <input
                                            ref="input_helper"
                                            v-model="seekPosition"
                                            type="range"
                                            :max="maxRange"
                                            :class="{onDrag: playerClass}"
                                            aria-label="seek bar helper"
                                            @click.stop
                                            @change="throttledSeek($event)"
                                            @mouseup="throttledSeek($event)"
                                            @mousedown="playerClass = true"
                                            @touchstart="playerClass = true"
                                        >
                                    </div>
                                </div>
                                <div class="@text-right p4">
                                    {{duration}}
                                </div>
                            </div>
                            <audio
                                ref="audio"
                                class="d-none"
                                preload="none"
                                @timeupdate="throttledTimeupdate(false)"
                                @ended="ended"
                                @loadedmetadata="loaded"
                            >
                                <source ref="source" :src="src">
                            </audio>
                        </div>
                        <button class="closeIcon @mb-6 @pb-0 desktop:@pb-[3px] tablet:@pl-6 small:@pl-5 @cursor-pointer" data-cy="close-audio-player-button" @click.prevent="$sfPlayer.hidePlayer(true)">
                            <img :src="$sfPlayer.icons.closeIcon" alt="close player">
                        </button>
                    </div>
                </div>
            </div>
            <div class="miniProgressBar">
                <div ref="mini_progress" class="headway"/>
            </div>
        </div>
    </transition>
</template>

<script>
    import dayjs from "dayjs";
    import RoundImageWithDefault from "@songfinch/shared/components/RoundImageWithDefault";
    import SongPlayerPlaybackReporter from "./song_player_playback_reporter";
    import axios from "axios";
    import analyticsEvent from "@songfinch/shared/helpers/analyticsEvent";
    import {throttle, isEqual} from "lodash-es";

    import DButton from "@songfinch/design-system/src/atoms/DButton.vue";

    export default {
        name: "AudioPlayer",
        components: {RoundImageWithDefault, DButton},
        props: {
            scrollableParent: {type: String, default: "body"} //querySelector
        },
        data() {
            return {
                currentTime: "0:00",
                maxRange: null,
                duration: "0:00",
                seekPosition: 0,
                playerClass: false,
                songLoaded: false,
                playbackReporter: null,
                throttledTimeupdate: () => undefined,
                throttledSeek: () => undefined,
                cancelTokenSource: null
            };
        },
        computed: {
            currentlyPlayingAudio() {
                return this.$sfPlayer.currentlyPlayingAudio;
            },
            isPlaying() {
                return this.$sfPlayer.isPlaying;
            },
            src() {
                if (typeof this.currentlyPlayingAudio.audio === "string" && /^https?:\/\//.test(this.currentlyPlayingAudio.audio)) {
                    return this.currentlyPlayingAudio.audio;
                }
                return this.$cld.url(this.currentlyPlayingAudio.audio);
            },
            close() {
                return this.$sfPlayer.hidePlayerState;
            },
            showNavigation() {
                return this.$sfPlayer.playlist.length > 1;
            },
            seekRedraw() {
                return this.$sfPlayer.playlist.seekRedraw;
            },
            showPlayer() {
                return this.currentlyPlayingAudio && !this.close;
            },
        },
        watch: {
            currentlyPlayingAudio: {
                handler: function (val, old) {
                    const isNotEqual = !isEqual(val?.audio, old?.audio);
                    if (isNotEqual && val?.audio) {
                        //Stop the HTML audio player if we are still playing
                        this.pause();
                        this.songLoaded = false;
                        this.$refs.source.src = this.src;
                        this.$refs.audio.load();
                        //Create a playback reporter which will track playback progress for
                        //the current song
                        this.playbackReporter = new SongPlayerPlaybackReporter(this);
                    }
                    //Song should come in not playing, this will sync things
                    this._syncGlobalPlayerToAudioRefState();
                },
                deep: true
            },
            "$sfPlayer.currentRange"() {
                this.$refs["input"].style.setProperty("--seek-before-width", this.$sfPlayer.currentRange / this.maxRange * 100 + "%");
                this.$refs["mini_progress"].style.setProperty("--seek-before-width", this.$sfPlayer.currentRange / this.maxRange * 100 + "%");
            },
            seekPosition() {
                this.$refs["input_helper"].style.setProperty("--seek-before-width", this.seekPosition / this.maxRange * 100 + "%");
            },
            "$sfPlayer.seekRedraw"(val) {
                if (val) this.updateSeek();
            }
        },
        mounted() {
            if (this.$sfPlayer.isRendered) {
                console.error("Error: The component can only be used once");
                this.$el.parentNode.removeChild(this.$el);
                return false;
            } else {
                this.$sfPlayer.setRendered(true);
            }
            if (this.scrollableParent) {
                const el = document.querySelector(this.scrollableParent);
                if (!el) return console.error("Can't find scrollableParent. Audio player minifyPlayer class won't be added");
                const scrollableElement = this.scrollableParent === "body" ? document.documentElement : el;
                el.onscroll = () => {
                    if (window.sfPlayerOldScroll < scrollableElement.scrollTop) {
                        document.body.classList.add("minifyPlayer");
                    }
                    window.sfPlayerOldScroll = scrollableElement.scrollTop;
                };
            }
            this.throttledTimeupdate = throttle(this.timeupdate, 500);
            this.throttledSeek = throttle(this.seek, 500, {trailing: false});
        },
        methods: {
            timeupdate(ended = false) {
                this.recalculatePlaybackState();
                this.playbackReporter?.onTimeUpdate(ended);
                analyticsEvent("_sf_real_time_song_listened", {song: this.getAudioFileMeta(), player: this.getPlaybackMeta()});
            },
            setDuration(d) {
                this.maxRange = Math.floor(d || 0);
                const duration = dayjs(this.maxRange * 1000);
                this.duration = duration.isValid() ? duration.format("m:ss") : "0:00";
            },
            async loadDuration() {
                const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
                this.cancelTokenSource = axios.CancelToken.source();

                try {
                    const res = await axios.get(this.src, {baseURL: "", responseType: "arraybuffer", cancelToken: this.cancelTokenSource.token});
                    return audioCtx.decodeAudioData(
                        res.data,
                        (buffer) => {
                            this.setDuration(buffer.duration);
                            this.recalculatePlaybackState();
                        }
                    );
                } catch (e) {
                    if (!axios.isCancel(e)) throw new Error(e.message || "failed to load duration");
                }
            },
            loaded() {
                if (this.cancelTokenSource) {
                    this.cancelTokenSource.cancel();
                }
                this.songLoaded = true;
                this.setDuration(this.$refs.audio.duration);
                if (this.$refs.audio.duration === Infinity) {
                    this.loadDuration();
                } else {
                    this.recalculatePlaybackState();
                }
                //If the song has loaded, and it's playing, then we skipped reporting in
                //the play method and we should now report progress
                this.playbackReporter?.onLoaded();
            },
            updateSeek() {
                this.playerClass = false;
                this.$refs.audio.currentTime = this.$sfPlayer.currentRange;
                this.recalculatePlaybackState();
                if (!this.isPlaying) this.$sfPlayer.play();
                this.$sfPlayer.seekRedraw = false;
            },
            seek(e) {
                this.playerClass = false;
                this.$refs.audio.currentTime = e.target.value;
                this.recalculatePlaybackState();
                //If the song is currently not playing, activate it via an update
                //to the sfPlayer controller
                if (!this.isPlaying) this.$sfPlayer.play();
            },
            play() {
                this.$refs.audio.play();
                this.playbackReporter?.onPlay();
            },
            pause() {
                this.$refs.audio.pause();
            },
            ended() {
                this.timeupdate(true);
                //Notify the player that the song has ended
                this.$sfPlayer.onSongEnded();
            },
            recalculatePlaybackState() {
                this.$sfPlayer.currentRange = Math.floor(this.$refs.audio.currentTime || 0);
                this.currentTime = dayjs(this.$sfPlayer.currentRange * 1000).format("m:ss");
            },
            getAudioFileMeta() {
                return this.currentlyPlayingAudio;
            },
            getPlaybackMeta() {
                return {
                    currentRange: this.$sfPlayer.currentRange,
                    currentTime: this.currentTime,
                    maxRange: this.maxRange,
                    duration: this.duration,
                    seekPosition: this.seekPosition,
                };
            },
            _syncGlobalPlayerToAudioRefState() {
                //If the audio file is marked as playing, then we want to trigger the HTML audio element
                this.isPlaying ? this.play() : this.pause();
            },
        }
    };
</script>

<style>
    @import "@songfinch/customer/stylesheets/includes/_variables.pcss";

    .player-animation-enter {
        transform: translateY(100%);
    }

    .player-animation-enter-active, .player-animation-leave-active {
        transition: all 0.3s ease-in-out;
    }

    .player-animation-enter-to {
        transform: translateY(0);
    }

    .player-animation-enter-from, .player-animation-leave-to {
        transform: translateY(100%);
    }

    .mkAudioPlayerBox {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #FFFFFF;
        z-index: 1001;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1), 0 0 23px rgba(0, 0, 0, 0.05);
        border-radius: 15px 15px 0 0;
        transition: 0.3s;

        @screen tablet {
            height: 130px;
        }

        .closeIcon {
            position: absolute;
            right: 6px;
            top: 6px;
            @screen tablet {
                position: static;
            }
        }

        .songDetails {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            @screen tablet {
                justify-content: start;
                text-align: left;
                .h5, .p3, .p4 {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            > .frameBox {
                width: 82px;
                min-width: 82px;
                display: none;
                @screen tablet-lg {
                    display: block;
                }
            }

            .h5 {
                text-decoration-thickness: 1px !important;
                text-underline-offset: 4px;
            }

            .p3 {
                color: #54524C
            }

            .p4 {
                color: #83817B;
            }


        }

        .mkAudioProgressBar {
            .p4 {
                color: #54524C;
                white-space: nowrap;
                width: 40px;
            }

            @screen mobile {
                position: relative;
                .p4 {
                    position: absolute;
                    bottom: -6px;
                    right: 0;

                    &:first-child {
                        left: 0;
                        right: auto;
                        z-index: 99;
                    }
                }
            }
        }

        .mkAudioPlayer {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            @screen tablet {
                max-width: 632px;
                display: block;
            }

            .controlPanel {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 1;

                .mkPlayButton {
                    width: 46px;
                    height: 46px;
                    min-width: 46px;
                    background-color: #F64D1C;
                    color: #FFFFFF;
                    cursor: pointer;
                    border-radius: 50%;
                    position: relative;

                    @screen mobile {
                        width: 62px;
                        height: 62px;
                        min-width: 62px;
                    }
                }

                img {
                    width: 100%;
                    cursor: pointer;
                }

                button.prev {
                    transform: scaleX(-1);
                }

                @screen mobile {
                    div:not(.mkPlayButton) {
                        width: 36px;
                        height: 36px;
                    }
                }
            }

            .helper {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;

                &.show {
                    opacity: 1;
                }
            }

            input[type="range"] {
                position: relative;
                -webkit-appearance: none;
                width: 100%;
                margin: 0;
                padding: 0;
                height: 54px;
                margin: 0;
                outline: none;

            }

            input[type="range"]::-webkit-slider-runnable-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                background: #F3F1EB;
                border-radius: 8px;
            }

            input[type="range"]::before {
                position: absolute;
                content: "";
                top: 25px;
                left: 0;
                width: var(--seek-before-width);
                height: 4px;
                background-color: #262420;
                cursor: pointer;
                border-radius: 8px 0 0 8px;
            }

            input[type="range"]::-webkit-slider-thumb {
                position: relative;
                -webkit-appearance: none;
                box-sizing: border-box;
                border: 1px solid #262420;
                box-shadow: 0 0 0 14px transparent;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background-color: #262420;
                cursor: pointer;
                margin: -4px 0 0 0;
                transition: 0.3s;
            }

            input[type="range"]:active::-webkit-slider-thumb, input[type="range"].onDrag::-webkit-slider-thumb {
                transform: scale(1.4);
            }

            input[type="range"]::-moz-range-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                background: #F3F1EB;
            }

            input[type="range"]::-moz-range-progress {
                background-color: #262420;
            }

            input[type="range"]::-moz-focus-outer {
                border: 0;
            }

            input[type="range"]::-moz-range-thumb {
                box-sizing: border-box;
                border: 1px solid #262420;
                box-shadow: 0 0 0 14px transparent;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background-color: #262420;
                cursor: pointer;
                transition: 0.3s;
            }

            input[type="range"]:active::-moz-range-thumb, input[type="range"].onDrag::-moz-range-thumb {
                transform: scale(1.4);
            }

            input[type="range"]::-ms-track {
                width: 100%;
                height: 4px;
                cursor: pointer;
                background: transparent;
                border: solid transparent;
                color: transparent;
                border-radius: 8px;
            }

            input[type="range"]::-ms-fill-lower {
                background-color: #262420;
            }

            input[type="range"]::-ms-fill-upper {
                background: #F3F1EB;
            }

            input[type="range"]::-ms-thumb {
                position: relative;
                box-sizing: border-box;
                border: 1px solid #262420;
                box-shadow: 0 0 0 14px transparent;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background-color: #262420;
                cursor: pointer;
                margin: -4px 0 0 0;
                transition: 0.3s;
            }

            input[type="range"]:active::-ms-thumb, input[type="range"].onDrag::-ms-thumb {
                transform: scale(1.4);
            }
        }
    }

    @screen mobile {
        .minifyPlayer {
            .playerMinified {
                pointer-events: none;
            }

            .mkAudioPlayerBox {
                padding-bottom: 20px !important;

                .closeIcon {
                    right: 6px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .miniProgressBar {
                    width: 100%;
                    height: 4px;
                    background-color: #F3F1EB;
                    position: absolute;
                    left: 0;
                    bottom: 0;

                    .headway {
                        height: 4px;
                        width: var(--seek-before-width);
                        background-color: #262420;
                    }
                }

                .songDetails {
                    justify-content: start;
                    text-align: left;
                    margin-left: 58px;
                    margin-top: -8px;
                    min-height: 50px;

                    .h5 {
                        font-size: 20px;
                        line-height: 22px;
                    }

                    .p3, .h5 {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: calc(100vw - 130px);
                    }

                    .p4 {
                        display: none;
                    }

                }

                .mkAudioPlayer {
                    .controlPanel {
                        & > button:not(.mkPlayButton) {
                            display: none;
                        }

                        .mkPlayButton {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            margin: 0 !important;
                            width: 48px;
                            height: 48px;
                            min-width: 48px;
                        }
                    }

                    .mkAudioProgressBar {
                        display: none !important;
                    }
                }
            }
        }
    }

    #app .mkAudioPlayerBox {
        z-index: var(--zix-audio-player-global);
        background: var(--sfc-white);

        .songDetails {
            .p3 {
                color: var(--sfc-dark-grey);
            }

            .p4 {
                color: var(--sfc-medium-grey);
            }
        }

        .mkAudioProgressBar {
            .p4 {
                color: var(--sfc-dark-grey);
            }
        }

        .mkAudioPlayer {
            .controlPanel {
                .mkPlayButton {
                    background-color: var(--sfc-cherry);
                    color: var(--sfc-white);
                }
            }

            input[type="range"]::-webkit-slider-runnable-track {
                background: var(--sfc-beige);
            }

            input[type="range"]::before {
                width: var(--seek-before-width);
                background-color: var(--sfc-black);
            }

            input[type="range"]::-webkit-slider-thumb {
                border: 1px solid var(--sfc-black);
                background-color: var(--sfc-black);
            }

            input[type="range"]::-moz-range-track {
                background: var(--sfc-beige);
            }

            input[type="range"]::-moz-range-progress {
                background-color: var(--sfc-black);
            }

            input[type="range"]::-moz-range-thumb {
                border: 1px solid var(--sfc-black);
                background-color: var(--sfc-black);
            }

            input[type="range"]::-ms-fill-lower {
                background-color: var(--sfc-black);
            }

            input[type="range"]::-ms-fill-upper {
                background: var(--sfc-beige);
            }

            input[type="range"]::-ms-thumb {
                border: 1px solid var(--sfc-black);
                background-color: var(--sfc-black);
            }
        }
    }

    @screen mobile {
        .minifyPlayer {
            .mkAudioPlayerBox {
                .miniProgressBar {
                    background-color: var(--sfc-beige);

                    .headway {
                        width: var(--seek-before-width);
                        background-color: var(--sfc-black);
                    }
                }
            }
        }
    }
</style>


